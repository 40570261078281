import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PinInput from "react-pin-input";
//import moment from "moment";
import swal from "sweetalert";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useDispatch, useSelector, connect } from "react-redux";

import "./Gate.css"
import { Row, Col } from 'react-bootstrap';

function Gate(props) {
  const incidentcallstate = useSelector((state) => state.callstatsReducer.incidentcallstats);
  const [value, setValue] = useState("");
  const [pinTime, setPinTime] = useState(new Date());
  const [layoutName] = useState("default");

  let pin = useRef();
  let keyboard = useRef();

  function tick() {
    setPinTime(new Date());
  }

  function onChange(value) {
    setValue(value);
  }

  function onKeyPress(button) {
    console.log("onKeyPress", button);
    if (button === "{clear}" || button === "{bksp}") {
      handleClear();
    } else {
      if (pin.elements[2].state.value) {
        pin.elements[3].state.value = button;
        //setTimeout(onSubmitHandler, 1500);
        return;
      }
      if (pin.elements[1].state.value) {
        pin.elements[2].state.value = button;
        return;
      }
      if (pin.elements[0].state.value) {
        pin.elements[1].state.value = button;
        return;
      }
      pin.elements[0].state.value = button;
    }
  }

  function handleClear() {
    console.log("handleClear");
    setValue(null);
    //keyboard.clearInput();
    pin.elements[0].state.value = "";
    pin.elements[1].state.value = "";
    pin.elements[2].state.value = "";
    pin.elements[3].state.value = "";

    console.log("handleClear after");
  }

  useEffect(() => {
    console.log(value);
    if (value.length > 3) {
      swal("Please Wait");
      
      const postpayload = {
        pincode: value,
        unit: incidentcallstate.unitcode.toUpperCase()
      };
      axios.post('/api/Gate/validate', postpayload)
        .then(res => {
          console.log(res);
          console.log(res.data);
          if (!res.data.success){
            swal("Invalid PIN!", "Pin you enter didn't match. Try again", "error")
            .then((result) => {
              if (result) {
                window.location.reload();
              }
            });
          } else {
            swal("Success", "Please wait while we open the gate", "success");
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });
    }
  }, [value, incidentcallstate.unitcode]);

  useEffect(() => {
    const timerID = setInterval(() => {
      tick()
    }, 1000);

    return () => clearInterval(timerID);
  }, []);

  return (
    <Row>
      <Col className="Pin home-container">
        <div className="text text-center">
          <h1>{incidentcallstate.unitcode.toUpperCase()} Gate</h1>
          <h2 id="todaysDate">{pinTime.toLocaleTimeString()}</h2>
        </div>
        <PinInput
          length={4}
          //focus
          ref={(p) => (pin = p)}
          type="numeric"
          inputMode="number"
          onChange={onChange}
          //onComplete={onSubmitHandler}
        />

        <Keyboard
          keyboardRef={(r) => (keyboard = r)}
          layoutName={layoutName}
          theme={
            "hg-theme-default hg-theme-numeric hg-layout-numeric numeric-theme"
          }
          layout={{
            default: ["1 2 3", "4 5 6", "7 8 9", "{clear} 0 {bksp}"]
          }}
          mergeDisplay
          display={{
            "{clear}": "Clear",
            "{bksp}": "&#8592"
          }}
          maxLength={4}
          onChange={(input) => onChange(input)}
          onKeyPress={(button) => onKeyPress(button)}
          //onComplete={onSubmitHandler}
        />
      </Col>
    </Row>
  );
}

export default connect()(Gate);