import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <Row>
        <Col className="Pin home-container">
          <h1>Restricted</h1>
          <p>Please go away</p>
          <div className="d-grid gap-2">
            <Link className="btn btn-primary btn-lg" to="/gate">
              Gate
            </Link>
            <Button variant="outline-success" size="lg" href="https://chat.whatsapp.com/FL26F0o9Kkg06X7DbIcLTE">
              WhatsApp - Internal
            </Button>
            <Button variant="outline-primary" size="lg" href="https://teams.microsoft.com/l/channel/19%3a49a74cd2ef904392bec010e22877a4c5%40thread.tacv2/General?groupId=124f7729-4422-47fd-befa-cd91d343172f&tenantId=0444302a-cf7c-4bad-bb4e-72c3e6b8409d">
              MS Teams
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}
