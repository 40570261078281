import { connection } from "../../store/middlewares/signalR";
import { HubConnectionState } from 'redux-signalr';

export const DIRECT_MESSAGE = "[CHAT APP] DIRECT MESSAGE";
export const RECEIVE_MESSAGE = "[CHAT APP] RECEIVE MESSAGE";
export const EAS_MESSAGE = "EAS_MESSAGE";
export const UNIT_UPDATE = "UNIT_UPDATE";
export const UNIT_CODE = "UNIT_CODE";
export const SET_SIGNALRSTATE = "SET_SIGNALRSTATE";

export const FETCH_CALLSTATS_REQUEST = "FETCH_CALLSTATS_REQUEST";
export const FETCH_CALLSTATS_SUCCESS = "FETCH_CALLSTATS_SUCCESS";
export const FETCH_CALLSTATS_FAILURE = "FETCH_CALLSTATS_FAILURE";

export function receiveSocketMessage(data) {
  return (dispatch) => {
    dispatch({ type: RECEIVE_MESSAGE, payload: `test` });
  };
}

export function directMessage(data) {
  return {
    type: DIRECT_MESSAGE,
    payload: data
  };
}

export function AddToGroup(data) {
  return async (dispatch, getState, invoke) => {
    await invoke("AddToGroup", data.toUpperCase());
  };
}

export function EASMessageReceived(data) {
    //dispatch({ type: EAS_MESSAGE, payload: data });
}

export function Ping() {
  return async (dispatch, getState, invoke) => {
    const incidentcallstate = getState().callstatsReducer.incidentcallstats;
    //console.log("Starting Ping Function");
    if (!incidentcallstate.signalr){
      dispatch({type: SET_SIGNALRSTATE, payload: true});
      if (incidentcallstate.unitcode !== ""){
        dispatch(AddToGroup(incidentcallstate.unitcode.toUpperCase()));
      }
    }
    //console.log("Sending Ping");
    await invoke("Ping", incidentcallstate.unitcode.toUpperCase());
  }
}

export function setUnitCode(data) {
  return {
    type: UNIT_CODE,
    payload: data
  }
}

export function getStats(){
  return(dispatch, getState) => {
    const incidentcallstate = getState().callstatsReducer.incidentcallstats;
    dispatch({type: FETCH_CALLSTATS_REQUEST});
    const params = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        brigade: incidentcallstate.unitcode.toUpperCase()
      })
    }
    return fetch(`/api/CallStats/getStats`, params)
    .then(response => response.json())
    .then(data => {
      dispatch({type: FETCH_CALLSTATS_SUCCESS});
      dispatch({type: UNIT_UPDATE, payload: data});
    })
    .catch (error => {
      dispatch({type: FETCH_CALLSTATS_FAILURE, error})
    })
  }
}

export function SignalrState(data) {
  return (dispatch, getState, invoke) => {
    const incidentcallstate = getState().callstatsReducer.incidentcallstats;
    if (!data && incidentcallstate.signalr){
      dispatch({
        type: SET_SIGNALRSTATE,
        payload: data
      })
    }
  }
}