import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from "react-redux";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel';
import * as Actions from "./incidentcallstats.actions";
import ApplianceStatus from '../../components/ApplianceStatus';
import { connection } from "../../store/middlewares/signalR";

import './IncidentCallStats.css'
import { HubConnectionState } from 'redux-signalr';
import moment from 'moment/moment';

function IncidentCallStats(props) {
  const dispatch = useDispatch();
  let incidentcallstate = props.incidentcallstate;
  const [showStats, setShowStats] = useState(false);

  useEffect(() => {
    var mainHTML = document.documentElement;
    mainHTML.setAttribute('data-theme', "dark");
  }, []);

  useEffect(() => {
    if (incidentcallstate.unitcode !== ""){
      dispatch(Actions.getStats());
    }
    const statinterval = setInterval(() => {
      if (incidentcallstate.unitcode !== ""){
        dispatch(Actions.getStats());
      }
    }, 120000);
    return () => clearInterval(statinterval);
  }, [incidentcallstate.unitcode, dispatch]);

  useEffect(() => {
    //console.log("Brigade Status: " + incidentcallstate.isActive);
    setShowStats(incidentcallstate.isActive);
  }, [incidentcallstate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (connection.state === HubConnectionState.Connected){
        dispatch(Actions.Ping());
      } else {
        dispatch(Actions.SignalrState(false));
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [incidentcallstate.unitcode, dispatch]);

  return (
  <Container className="incident-stats text-white bg-dark">
  { showStats ? (
    <>
      <Carousel
          interval={10000}
          slide={true}
          indicators={false}
          controls={false}
        >
          <Carousel.Item>
            <Row>
                <Col className="text-center">
                  <h3><span id="brigadename">{incidentcallstate.unitcode.toUpperCase()}</span> Brigade Stats</h3>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                      <h1 className="display-1" id="numcalls">{incidentcallstate.brigadecalls} incidents</h1>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                      <h4>since {incidentcallstate.callssince}</h4>
              </Col>
            </Row>
            <div className="stats-footer">
            { incidentcallstate.lastpagermessage !== "" ? (
              <Container>
                <Row>
                  <Col id="lastpagermessage">Last message for {incidentcallstate.lastbrigademessage} [{incidentcallstate.lastagency}]: {incidentcallstate.lastpagermessage} - {moment(incidentcallstate.lastpagertime).fromNow()}</Col>
                </Row>
              </Container>
            ): null }
            </div>
          </Carousel.Item>
          { incidentcallstate.appliances.length > 0 ? (
          <Carousel.Item>
            <ApplianceStatus vehicles={incidentcallstate.appliances} />
          </Carousel.Item>
          ) : null }
        </Carousel>
      </>
    ) : null }
  </Container>)
}

const mapStateToProps = (state) => {
  return {
    incidentcallstate: state.callstatsReducer.incidentcallstats
  }
}

export default connect(mapStateToProps)(IncidentCallStats);