import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card';

function ApplianceStatus(props){
  return (
    <Row>
    { props.vehicles.map((appliance, key) => {
      return (
        <Col sm={6} className={"card card-appliance bg-gradient mb-1 " + appliance.cssclass} id={"appliance-" + appliance.id} key={key}>
          <Card.Body className={"text-center card-status"}>
            <h2 className={"display-6"}>{appliance.todisplayname}</h2>
            <p className={"card-text"} id={"appliance-" + appliance.id + "-txt"}>{appliance.statustext}</p>
          </Card.Body>
        </Col>
        )
      })
    }
    </Row>
  );
}

export default ApplianceStatus;