import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import './Layout.css'
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as Actions from "./incidentcallstats/incidentcallstats.actions"

var host = window.location.host;
var parts = host.split(".");

export function Layout(props) {
  const dispatch = useDispatch();
  console.log("Hostname: " + parts[0])
  useEffect(() => {
      console.log("useEffect: " + parts[0]);
      dispatch(Actions.setUnitCode(parts[0]));
  }, [parts[0]]);
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Link to="/" className="titleLink"><h3>FireBrigade.cloud</h3></Link>
          </Col>
        </Row>
        {props.children}
      </Container>
    </div>
  );
}
