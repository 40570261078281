import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import signalr from "./middlewares/signalR";
import callstatsReducer from "../components/incidentcallstats/reducers";
import { createLogger } from "redux-logger";

const logger = createLogger();
//const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware, signalRMiddleware, logger));

const rootReducer = combineReducers({
  callstatsReducer
});

const store = configureStore({
  reducer: rootReducer, 
  middleware: [signalr, logger ]
})

export default store