import { Home } from "./components/Home";
import Gate from "./components/Gate";
import IncidentCallStats from "./components/incidentcallstats/index";

const AppRouter = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/gate',
    element: <Gate />
  },
  {
    path: '/incidentcallstats',
    element: <IncidentCallStats />
  }
];

export default AppRouter;
