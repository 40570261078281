import {
  HubConnectionBuilder,
  withCallbacks,
  signalMiddleware,
  LogLevel
} from "redux-signalr";
import * as Actions from "../../components/incidentcallstats/incidentcallstats.actions";


export const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl('/Screen')
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
.add("MessageReceived", (data) => (dispatch) => {
  console.log(data);
  dispatch({type: Actions.EAS_MESSAGE, payload: data});
})
.add("Pong", (msg) => (dispatch) => {
  //console.log("Received Pong");
})
.add('ReceiveMessage', (msg) => (dispatch) => {
  //dispatch(setText(msg));
})
.add('Send', (msg) => (dispatch) => {
  console.log(msg);
  //dispatch({type: "test", payload: "test"});
})
.add("UpdateStatus", (data) => (dispatch) => {
  //console.log(data);
  //dispatch({type: Actions.UNIT_UPDATE, payload: data});
  dispatch(Actions.getStats());
});

export const signalr = signalMiddleware({
  callbacks,
  connection,
  //shouldConnectionStartImmediately: false
});

export default signalr;