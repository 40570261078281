import * as Actions from "../incidentcallstats.actions";

const initialState = {
  unitcode: "",
  loading: true,
  appliances: [],
  lastpagertime: null,
  lastbrigademessage: "",
  lastpagermessage: "",
  lastagency: "",
  brigadecalls: 0,
  callssince: "",
  isActive: false,
  signalr: false
};

const callstatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.RECEIVE_MESSAGE: {
      return {
        ...state,
        directMessage: action.payload
      };
    }
    case Actions.EAS_MESSAGE: {
      return {
        ...state,
        lastpagermessage: action.payload.message,
        lastbrigademessage: action.payload.unitname,
        lastagency: action.payload.agency,
        lastpagertime: new Date()
      }
    }
    case Actions.UNIT_UPDATE: {
      return {
        ...state,
        brigadecalls: action.payload.numCalls,
        callssince: action.payload.reportingperiodstart,
        appliances: action.payload.applianceStatuses,
        isActive: action.payload.isActive
      }
    }
    case Actions.UNIT_CODE: {
      return {
        ...state,
        unitcode: action.payload
      }
    }
    case Actions.SET_SIGNALRSTATE: {
      return {
        ...state,
        signalr: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default callstatsReducer;
